@import url("https://fonts.googleapis.com/css2?family=Licorice&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Licorice&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

html {
  height: 100vh; }

body {
  width: 100%;
  height: 100vh;
  box-sizing: border-box; }

.footerTextL {
  color: white;
  font-size: 15px; }

.ulFooter, .liFooter, .linkFooter {
  list-style: none;
  text-decoration: none;
  display: inline-block;
  color: white;
  font-size: 15px;
  text-shadow: none;
  font-family: "Times New Roman", Times, serif; }

div#kt_footer.py-4 {
  padding-bottom: 0rem !important; }

#kt_footer {
  background-color: #df7373; }

.container-fluid {
  margin-bottom: 5px; }

.footerL {
  margin-left: 20px;
  margin-bottom: 15px; }

.footerR {
  margin-right: 10px; }

@media only screen and (max-width: 769px) {
  .footerR {
    display: grid;
    text-align: center;
    padding: 0; }
    .footerR li {
      padding-bottom: 10px; }

  .footerL {
    padding: 20px 0 20px 0 !important;
    margin: 0 0 0 0; }

  html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR li.liFooter a.linkFooter.px-2 {
    padding: 0;
    margin: 0; }

  html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR {
    margin-bottom: 0; }

  html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR {
    margin: 0; } }
.phoneMenu {
  display: none; }

.navbar {
  background-color: #df7373; }

.navbar-collapse {
  flex-grow: 0;
  margin-right: 50px; }

.navbar-brand {
  color: white;
  font-family: "Licorice", cursive;
  font-size: 50px;
  height: 50px;
  margin: -10px 0px 10px 40px;
  text-shadow: 0px 1px 0px black;
  pointer-events: none; }

.nav-item, a, .nav-link {
  text-decoration: none;
  margin-left: 20px;
  color: white;
  font-size: 20px;
  font-family: "Lora", serif;
  text-shadow: 0px 1px 0px black; }
  .nav-item:hover, .nav-item:active, .nav-item:focus, a:hover, a:active, a:focus, .nav-link:hover, .nav-link:active, .nav-link:focus {
    color: rgba(255, 255, 255, 0.767) !important; }

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.767) !important; }

@media screen and (max-width: 768px) {
  .navbar-brand {
    margin-left: 0; }

  .phoneMenu {
    padding: 10px 0 0px 0;
    display: block;
    fill: white;
    background: transparent;
    border: none; }

  .phoneMenu-active {
    display: block; }

  .mobMenu {
    height: 100%;
    width: 100%; }
    .mobMenu ul {
      margin-top: 10px; }
      .mobMenu ul li {
        margin: 0px; }
        .mobMenu ul li a {
          text-align: center; } }
@media screen and (min-width: 768px) and (max-width: 992px) {
  html body div#root div nav.navbar.navbar-expand-lg div.container-fluid div#navbarText.collapse.navbar-collapse {
    display: flex !important; }

  html body div#root div nav.navbar.navbar-expand-lg div.container-fluid div#navbarText.collapse.navbar-collapse ul.navbar-nav.me-auto.mb-2.mb-lg-0 {
    flex-direction: row;
    margin: 0 !important; }

  .navbar-collapse {
    flex-basis: 0 !important; } }
li {
  list-style: none !important; }

.pagination {
  margin-top: 40px; }

i.page-link {
  color: #df7373;
  cursor: pointer; }
  i.page-link:hover {
    color: #df7373; }

li.page-item.active i.page-link {
  background-color: #df7373;
  border-color: #df7373; }

html body div#root section.containter.top div.row.slideshow-row {
  margin-right: 0; }

.slideShow {
  margin: 10px auto; }

#slider {
  overflow: hidden; }

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms; }

.slide {
  display: inline-block !important;
  border-radius: 20px; }

/* Buttons */
.slideshowDots {
  text-align: center; }

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4; }

.slideshowDot.active {
  background-color: #df7373; }

.top-1 {
  margin-left: 20px;
  margin-top: 10px; }

.top-2 {
  margin-top: 30px;
  padding: 50px; }

.top-2 h1, .bottom h1 {
  font-family: "Licorice", cursive;
  color: #df7373;
  text-shadow: 0px 1px 0px black;
  font-size: 5rem !important;
  text-align: center; }

div.col-5.top-2 {
  width: 47%; }

.top-2 p, .bottom p {
  font-family: "Lora", serif;
  text-align: center; }

.top-2 p {
  font-size: 1.7rem; }

.bottom p {
  font-size: 1.5rem; }

div.d-flex.justify-content-center.align-items-center.p-4.bg-blue.bg-opacity-10.rounded-3 {
  background-color: #1EA896; }

div.d-flex.justify-content-center.align-items-center.p-4.bg-purple.bg-opacity-10.rounded-3 {
  background-color: #df7373; }

div.d-flex.justify-content-center.align-items-center.p-4.bg-info.bg-opacity-10.rounded-3 {
  background-color: #7776bc !important; }

div.ms-4.h6.fw-normal p.mb-0 {
  font-size: 1.25rem; }

div.ms-4.h6.fw-normal.adjust-marg-clock {
  margin-right: -30px !important; }

.bottom {
  margin-top: 30px;
  margin-bottom: 50px; }

.bottom img {
  margin: 10px 10px 10px 0px; }

.bottom ul {
  padding-left: 0px !important; }

.rezervacija-col {
  display: flex;
  justify-content: center; }

.rezervacija {
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  font-size: 50px;
  font-family: "Licorice", cursive;
  justify-items: center; }

.shadowing {
  box-shadow: 2px 2px 2px rgba(1, 1, 1, 0.5); }

.rezervacijaLink {
  margin-left: 0px !important; }

@media screen and (max-width: 768px) {
  p.navbar-brand {
    margin-left: 30px; }

  .top-1 {
    margin-top: 10px; }

  .top-2 {
    margin-top: -30px !important;
    margin-bottom: -20px !important; }

  .slideshow-col {
    width: 100%;
    margin: 10px 0; }

  .slideShow {
    margin: 0px; }

  .slideshow-row {
    margin: 0px; }

  .header-col {
    width: 100% !important;
    margin: 30px 0 30px 0; }

  .top-2 h1, .bottom h1 {
    font-size: 70px !important; }

  .top-2 p, .bottom p {
    font-size: 20px !important;
    text-align: center;
    margin-top: 20px; }

  html body div#root section.mid {
    margin-bottom: 30px; }

  .bottom-1, .bottom-2 {
    box-sizing: border-box;
    width: 100%;
    float: none;
    flex: auto;
    margin-top: 10px; }

  .bottom-1 h1, .bottom-2 h1 {
    margin-bottom: 20px; } }
@media screen and (min-width: 768px) and (max-width: 1082px) {
  .top-1 {
    margin-top: 30px; }

  .top-2 {
    margin-top: 0px; }

  .top-2 h1, .bottom h1 {
    font-size: 3rem !important; }

  .top-2 p, .bottom p {
    font-size: 1rem; } }
@media screen and (min-width: 1082px) and (max-width: 1520px) {
  .top-2 {
    margin-top: 30px;
    padding-top: 35px; }

  .top-2 h1, .bottom h1 {
    font-size: 4rem !important; }

  .top-2 p, .bottom p {
    font-size: 1.3rem; } }
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: inline;
    opacity: 0; }
  100% {
    display: inline;
    opacity: 1; } }
html body div#root div div#cardContent.album.py-5.bg-light div.container div#cardlist.row.row-cols-1.row-cols-sm-2.row-cols-md-3.g-3 li div.col div.card img.bd-placeholder-img.card-img-top:hover {
  transform: scale(1.02); }

div.card {
  cursor: pointer; }

.currentImage, .currentImage-active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .currentImage-active {
    display: flex;
    animation: fadeIn 0.5s ease-in-out; }

.fullScreenImage {
  width: calc( 100% - 40px );
  height: calc( 100% - 40px );
  display: flex;
  align-items: center;
  justify-content: center; }
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    user-select: none; }

.buttonClose, .buttonNext, .buttonPrev, .counter-box {
  position: fixed;
  cursor: pointer;
  opacity: 0.8;
  color: white;
  z-index: 5;
  background: transparent;
  border: none; }
  .buttonClose:hover, .buttonNext:hover, .buttonPrev:hover, .counter-box:hover {
    opacity: 1; }
  .buttonClose svg, .buttonNext svg, .buttonPrev svg, .counter-box svg {
    fill: #df7373; }

.buttonClose {
  top: 25px;
  right: 25px; }

.buttonNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px; }

.buttonPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px; }

.counter {
  font-size: 15px;
  color: white;
  text-align: center; }

.counter-box {
  top: 95%;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 90px;
  width: 60px;
  height: 27px;
  text-align: center; }

html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 {
  margin-right: 0px; }

html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5 {
  padding: 0 !important; }

.kontakt h1 {
  font-size: 40px;
  font-family: "Lora", serif;
  color: #df7373;
  text-shadow: 0px 1px 0px black;
  margin-bottom: 2.5rem !important; }

.kontakt h4 {
  color: #df7373;
  font-weight: 700;
  font-family: "Licorice", cursive;
  font-size: 50px;
  text-shadow: 0px 1px 0px black; }

.kontakt a {
  color: #df7373; }

.spanLine {
  width: 36px;
  height: 1px; }

.dugmence {
  background-color: #df7373;
  color: white;
  border-color: white;
  border-radius: 20px; }
  .dugmence:hover, .dugmence:active, .dugmence:focus {
    background-color: white;
    border-color: #df7373;
    color: #df7373; }
    .dugmence:hover svg, .dugmence:active svg, .dugmence:focus svg {
      fill: #df7373; }
  .dugmence svg {
    fill: white; }

.mapouter, .map {
  width: 100%; }
  .mapouter iframe, .map iframe {
    width: 100%;
    height: 400px; }

.gmap_canvas.map {
  height: 400px; }

html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 a.btn.btn-primary.upute {
  margin-top: 40px; }

@media screen and (max-width: 768px) {
  .gmap_canvas.map {
    margin-bottom: 20px; }

  .kontakt,
  h1, h4, a, p, span {
    text-align: center; }

  html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 {
    justify-content: center; }

  html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 ul.list-unstyled.mb-0 li.pb-1.mb-2 div.d-flex.align-items-center, html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 ul.list-unstyled.mb-0 li div.d-flex.align-items-center {
    justify-content: center; }

  html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 a.btn.btn-primary.upute {
    margin: 0; }

  html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 {
    margin: 40px 0; } }


