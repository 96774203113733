li
    list-style: none !important


.pagination
    margin-top: 40px

i.page-link
    color: rgb(223, 115, 115)
    &:hover
        color: rgb(223, 115, 115)
    cursor: pointer
        

li.page-item.active i.page-link
    background-color: rgb(223, 115, 115)
    border-color: rgb(223, 115, 115)