.footerTextL
    color: white
    font-size: 15px

.ulFooter,.liFooter,.linkFooter
    list-style: none
    text-decoration: none
    display: inline-block
    color: white
    font-size: 15px
    text-shadow: none
    font-family: 'Times New Roman', Times, serif

div#kt_footer.py-4
    padding-bottom: 0rem !important

#kt_footer
    background-color: #df7373

.container-fluid,
    margin-bottom: 5px

.footerL
    margin-left: 20px
    margin-bottom: 15px

.footerR
    margin-right: 10px

@media only screen and (max-width: 769px)
    .footerR
        display: grid
        text-align: center
        padding: 0

        li
            padding-bottom: 10px

    .footerL
        padding: 20px 0 20px 0 !important
        margin: 0 0 0 0
    html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR li.liFooter a.linkFooter.px-2
        padding: 0
        margin: 0

    html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR
        margin-bottom: 0

    html body div#root div#kt_footer.footer.py-4.d-flex.flex-lg-column div.container-fluid.d-flex.flex-column.flex-md-row.align-items-center.justify-content-between ul.order-1.footerR
        margin: 0