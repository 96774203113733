@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap')

.phoneMenu
    //display: none

html body div#root section.containter.top div.row.slideshow-row
    margin-right: 0

.slideShow
    margin: 10px auto

#slider

    overflow: hidden

.slideshowSlider

    white-space: nowrap
    transition: ease 1000ms

.slide
    display: inline-block !important
    border-radius: 20px

/* Buttons */
.slideshowDots
    text-align: center

.slideshowDot
    display: inline-block
    height: 20px
    width: 20px
    border-radius: 50%
    cursor: pointer
    margin: 15px 7px 0px
    background-color: #c4c4c4
.slideshowDot.active
    background-color: rgb(223, 115, 115)

//section1
.top
    //margin-top: 30px

.top-1
    margin-left: 20px
    margin-top: 10px

.top-2
    margin-top: 30px
    padding: 50px

.top-2 h1, .bottom h1
    font-family: 'Licorice', cursive
    color: #df7373
    text-shadow: 0px 1px 0px black
    font-size: 5rem !important
    text-align: center

div.col-5.top-2
    width: 47%

.top-2 p, .bottom p
    font-family: 'Lora', serif
    text-align: center

.top-2 p
    font-size: 1.7rem

.bottom p
    font-size: 1.5rem

//section2
div.d-flex.justify-content-center.align-items-center.p-4.bg-blue.bg-opacity-10.rounded-3
    background-color: #1EA896

div.d-flex.justify-content-center.align-items-center.p-4.bg-purple.bg-opacity-10.rounded-3
    background-color: #df7373

div.d-flex.justify-content-center.align-items-center.p-4.bg-info.bg-opacity-10.rounded-3
    background-color: #7776bc !important

div.ms-4.h6.fw-normal p.mb-0
    font-size: 1.25rem

div.ms-4.h6.fw-normal.adjust-marg-clock
    margin-right: -30px !important

//bottom

.bottom
    margin-top: 30px
    margin-bottom: 50px

.bottom img
    margin: 10px 10px 10px 0px

.bottom ul
    padding-left: 0px !important

//rezervacija
.rezervacija-col
    display: flex
    justify-content: center

.rezervacija
    margin-top: 50px
    margin-bottom: 10px
    display: flex
    justify-content: center
    padding: 10px 20px
    font-size: 50px
    font-family: 'Licorice', cursive
    justify-items: center

.shadowing
    box-shadow: 2px 2px 2px rgba(1,1 ,1 ,0.5 )

.rezervacijaLink
    margin-left: 0px !important

//Phones
@media screen and (max-width:768px)

    p.navbar-brand
        margin-left: 30px

    .top-1
        margin-top: 10px
    .top-2
        margin-top: -30px !important
        margin-bottom: -20px !important

    //slider
    .slideshow-col
        width: 100%
        margin: 10px 0

    .slideShow
        margin: 0px

    .slideshow-row
        margin: 0px

    //tekst
    .header-col
        width: 100% !important
        margin: 30px 0 30px 0

    .top-2 h1, .bottom h1
        font-size: 70px !important
    .top-2 p, .bottom p
        font-size: 20px !important
        text-align: center
        margin-top: 20px

    html body div#root section.mid
        margin-bottom: 30px

    //bottom
    .bottom-1, .bottom-2
        box-sizing: border-box
        width: 100%
        float: none
        flex: auto
        margin-top: 10px

    .bottom-1 h1, .bottom-2 h1
        margin-bottom: 20px

//Tablets
@media screen and (min-width: 768px) and (max-width: 1082px)
    .top-1
        margin-top: 30px

    .top-2
        margin-top: 0px

    .top-2 h1, .bottom h1
        font-size: 3rem !important

    .top-2 p, .bottom p
        font-size: 1rem

//smaller screens
@media screen and (min-width: 1082px) and (max-width: 1520px)
    .top-2
        margin-top: 30px
        padding-top: 35px

    .top-2 h1, .bottom h1
        font-size: 4rem !important

    .top-2 p, .bottom p
        font-size: 1.3rem
