@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap')
.phoneMenu
  display: none
.navbar
  background-color: rgb(223, 115, 115)

.navbar-collapse
  flex-grow: 0
  margin-right: 50px

.navbar-brand
  color: white
  font-family: 'Licorice', cursive
  font-size: 50px
  height: 50px
  margin: -10px 0px 10px 40px
  text-shadow: 0px 1px 0px black
  pointer-events: none

.nav-item, a, .nav-link
  text-decoration: none
  margin-left: 20px
  color: white
  font-size: 20px
  font-family: 'Lora', serif
  text-shadow: 0px 1px 0px black
  &:hover, &:active, &:focus
    color: rgba(255, 255, 255, 0.767) !important

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active
  color: rgba(255, 255, 255, 0.767) !important
//Phones
@media screen and (max-width:768px)
  .navbar-brand
    margin-left: 0

  .phoneMenu
    padding: 10px 0 0px 0
    display: block
    fill: white
    background: transparent
    border: none

  .phoneMenu-active
    display: block

  .mobMenu
    height: 100%
    width: 100%

    ul
      margin-top: 10px
      li
        margin: 0px
        a
          text-align: center

//Tablets
@media screen and (min-width: 768px) and (max-width: 992px)
  html body div#root div nav.navbar.navbar-expand-lg div.container-fluid div#navbarText.collapse.navbar-collapse
    display: flex !important
  html body div#root div nav.navbar.navbar-expand-lg div.container-fluid div#navbarText.collapse.navbar-collapse ul.navbar-nav.me-auto.mb-2.mb-lg-0
    flex-direction: row
    margin: 0 !important
  .navbar-collapse
    flex-basis: 0 !important
