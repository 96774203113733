html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2
    margin-right: 0px
    
html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5
    padding: 0 !important

.kontakt h1
    font-size: 40px
    font-family: "Lora", serif
    color: #df7373
    //-webkit-text-stroke-width: 0.3px
    //-webkit-text-stroke-color: black
    text-shadow: 0px 1px 0px black
    margin-bottom: 2.5rem !important

.kontakt h4
    color: #df7373
    font-weight: 700
    font-family: 'Licorice', cursive
    font-size: 50px
    text-shadow: 0px 1px 0px black

.kontakt a
    color: #df7373

.spanLine
    width: 36px
    height: 1px

.dugmence
    background-color: #df7373
    color: white 
    border-color: white
    border-radius: 20px
    &:hover, &:active, &:focus
        background-color: white
        border-color: #df7373 
        color: #df7373
        svg
            fill: #df7373  
    svg
        fill: white

.mapouter, .map
    width: 100%
    iframe
        width: 100%
        height: 400px
.gmap_canvas.map
    height: 400px

html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 a.btn.btn-primary.upute
    margin-top: 40px

//Phones
@media screen and (max-width:768px)
    .gmap_canvas.map
        margin-bottom: 20px

    .kontakt,
    h1,h4, a, p, span
        text-align: center

    html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2
        justify-content: center

    html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 ul.list-unstyled.mb-0 li.pb-1.mb-2 div.d-flex.align-items-center, html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 ul.list-unstyled.mb-0 li div.d-flex.align-items-center
        justify-content: center

    html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2 a.btn.btn-primary.upute
        margin: 0

    html body div#root div section.container.pt-5.pb-lg-2.pb-xl-4.py-xxl-5.my-5.kontakt div.border-top.pb-sm-3.pt-4 div.row.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4.g-4.pt-2 div.col.pb-2
        margin: 40px 0 

//Tablets
@media screen and (min-width: 768px) and (max-width: 992px)
