
@keyframes fadeIn
    0%
        display: none
        opacity: 0

    1%
        display: inline
        opacity: 0
    100%
        display: inline
        opacity: 1

html body div#root div div#cardContent.album.py-5.bg-light div.container div#cardlist.row.row-cols-1.row-cols-sm-2.row-cols-md-3.g-3 li div.col div.card img.bd-placeholder-img.card-img-top
    &:hover
        transform: scale(1.02)

div.card
    cursor: pointer

.currentImage
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 4
    background-color: rgba(0, 0, 0, 0.8)
    display: none
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

    &-active
        @extend .currentImage
        display: flex
        animation: fadeIn 0.5s ease-in-out
.fullScreenImage
    width: calc( 100% - 40px )
    height: calc( 100% - 40px )
    display: flex
    align-items: center
    justify-content: center

    img
        max-width: 100%
        max-height: 100%
        pointer-events: none
        user-select: none

.buttonClose, .buttonNext, .buttonPrev, .counter-box
    position: fixed
    cursor: pointer
    opacity: 0.8
    color: white
    z-index: 5
    background: transparent
    border: none
    &:hover
        opacity: 1

    svg
        fill: #df7373

.buttonClose
    top: 25px
    right: 25px

.buttonNext
    top: 50%
    transform: translateY(-50%)
    right: 40px

.buttonPrev
    top: 50%
    transform: translateY(-50%)
    left: 40px

.counter
    font-size: 15px
    color: white
    text-align: center
    

.counter-box
    top: 95%
    transform: translateY(-50%)
    align-items: center
    justify-content: center
    background-color: rgba(0, 0, 0, 0.3)
    border-radius: 90px
    width: 60px
    height: 27px
    text-align: center
