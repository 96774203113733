*
    padding: 0
    margin: 0
    box-sizing: border-box
    
html
    height: 100vh

body
    width: 100%
    height: 100vh
    box-sizing: border-box
    

